var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c("LoginDialog", {
        ref: "login_dialog",
        on: { "login-success": _vm.handleLoginSuccess },
      }),
      _c("div", { staticClass: "el-login-footer" }, [
        _c(
          "span",
          [
            _vm._v(" Copyright © 2020 - " + _vm._s(_vm.year)),
            _c(
              "a",
              { attrs: { href: "https://www.jingyudao.cn", target: "_blank" } },
              [_vm._v("北京鲸鱼岛科技有限公司")]
            ),
            _vm._v(" All Rights Reserved.| ICP证："),
            _c(
              "a",
              {
                attrs: { href: "https://beian.miit.gov.cn", target: "_blank" },
              },
              [_vm._v("京ICP备19023686号-2号")]
            ),
            _vm._v(" | "),
            _c(
              "el-link",
              {
                staticStyle: { color: "#fff", "font-size": "12px" },
                attrs: { underline: false },
                on: {
                  click: function ($event) {
                    return _vm.$refs.login_dialog.showServiceAgreementDialog()
                  },
                },
              },
              [_vm._v("《服务条款》")]
            ),
            _c(
              "el-link",
              {
                staticStyle: { color: "#fff", "font-size": "12px" },
                attrs: { underline: false },
                on: {
                  click: function ($event) {
                    return _vm.$refs.login_dialog.showPrivacyClauseDialog()
                  },
                },
              },
              [_vm._v("《隐私条款》")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }