<template>
  <div class="login">
    <LoginDialog ref="login_dialog" @login-success="handleLoginSuccess"></LoginDialog>
     <!--  底部  -->
    <div class="el-login-footer">
      <span>
        Copyright © 2020 - {{year}}<a href="https://www.jingyudao.cn" target="_blank">北京鲸鱼岛科技有限公司</a> All Rights Reserved.| ICP证：<a href="https://beian.miit.gov.cn" target="_blank">京ICP备19023686号-2号</a>
        | <el-link @click="$refs.login_dialog.showServiceAgreementDialog()" :underline="false" style="color: #fff; font-size: 12px;">《服务条款》</el-link>
        <el-link @click="$refs.login_dialog.showPrivacyClauseDialog()" :underline="false" style="color: #fff; font-size: 12px;">《隐私条款》</el-link>
      </span>
    </div>
  </div>
</template>

<script>
import LoginDialog from '@/components/Login/index.vue'
  const TIME_COUNT = 60;
  import {
    getCodeImg,
    getQrImg,
    qrLoginStatus,
    getPwdMobileCaptcha,
    resetPwd,
    sendRegisterCode,
    handleRegister,
    sendMobileCode,
  } from "@/api/login";
  import Cookies from "js-cookie";
  import { encrypt, decrypt } from "@/utils/jsencrypt";

  export default {
    name: "Login",
    components: {LoginDialog},
    data() {
      return {
        codeUrl: "",
        cookiePassword: "",
        loginForm: {
          username: "",
          password: "",
          // rememberMe: false,
          code: "",
          smsCode: "",
          uuid: "",
        },
        
        loginRules: {
          username: [
            { required: true, trigger: "blur", message: "用户名不能为空" },
          ],
          password: [
            { required: true, trigger: "blur", message: "密码不能为空" },
          ],
          code: [
            { required: true, trigger: "change", message: "验证码不能为空" },
          ],
        },
        mobileLoginForm: {
          mobile: "",
          smsCode: "",
        },
        mobileLoginRules: {
          mobile: [
            { required: true, trigger: "blur", message: "手机号不能为空" },
          ],
          smsCode: [
            { required: true, trigger: "change", message: "验证码不能为空" },
          ],
        },
        protocolDialog: false,
        loading: false,
        redirect: undefined,
        year: new Date().getFullYear(),
        qrImg:
          "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/defaultQr.jpg", //扫码登录二维码默认图片
        qrTimer: null, //二维码定时器
        cur: 1, //tab切换cur 默认显示账号登录
        isValid: false, //扫码登录二维码是否失效，默认不失效
        isTestVersion: process.env.NODE_ENV === "development", //区分正是环境or测试环境
        isForgetPsd: false, //是否忘记密码
        pwdImgCaptcha: "",
        forgetPwdForm: {
          imgCaptcha: "",
          uuid: "",
          mobile: "",
          code: "",
          password: "",
          confirmPassword: "",
        },
        forgetPwdFormRules: {
          mobile: [
            { required: true, trigger: "blur", message: "手机号不能为空" },
          ],
          code: [
            {
              required: true,
              trigger: ["change", "change"],
              message: "验证码不能为空",
            },
          ],
          password: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: "密码不能为空",
            },
          ],
          confirmPassword: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: "请再次输入密码",
            },
          ],
        },
        isGetForgetPwdCode: true,
        forgetPwdTime: 60,
        forgetPwdCount: 0,
        forgetPwdTimer: null,
        canClick: true,
        // 新版
        isShowCode: false,
        isLogin: true,
        registerForm: {
          type: "phone", //类型：email、phone
          username: "",
          code: "",
          password: "",
          confirmPassword: "",
          registerAgreement: false,
        },
        registerFormRules: {
          username: [
            {
              required: true,
              trigger: "blur",
              validator: (rule, value, callback) => {
                let phoneReg = /^1[3|4|5|7|8][0-9]{9}$/;
                let mailReg =
                  /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
                if (this.registerForm.type == "phone") {
                  if (value == "" || !value) {
                    callback(new Error("注册手机号不能为空"));
                  } else if (!phoneReg.test(value)) {
                    callback(new Error("手机号码格式不正确"));
                  } else {
                    callback();
                  }
                } else {
                  if (value == "" || !value) {
                    callback(new Error("注册邮箱不能为空"));
                  } else if (!mailReg.test(value)) {
                    callback(new Error("邮箱格式不正确"));
                  } else {
                    callback();
                  }
                }
              },
            },
          ],
          code: [
            { required: true, trigger: "change", message: "验证码不能为空" },
          ],
          password: [
            { required: true, trigger: "blur", message: "密码不能为空" },
            {
              validator: (rule, value, callback) => {
                if (
                  /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,16}$/g.test(
                    value
                  )
                ) {
                  callback();
                } else {
                  callback(
                    new Error(
                      "必须包含字母、数字，至少8个字符，最多16个字符"
                    )
                  );
                }
              },
            },
          ],
          registerAgreement: [
            {
              type: "boolean",
              required: true,
              trigger: "change",
              validator: (rule, value, callback) => {
                if (!value) {
                  this.registerAgreementCheck = true;
                  callback(new Error("请阅读并同意此内容"));
                } else {
                  callback();
                  this.registerAgreementCheck = false;
                }
              },
            },
          ],
          confirmPassword: [
            {
              required: true,
              validator: (rule, value, callback) => {
                if (value == "" || !value) {
                  callback(new Error("再次输入密码不能为空"));
                } else if (value !== this.registerForm.password) {
                  callback(new Error("两次输入密码不一致"));
                } else {
                  callback();
                }
              },
              trigger: "blur",
            },
          ],
        },
        isGetCode: true,
        count: 0,
        timer: null,
        registerAgreementCheck: false,
        serviceAgreementDialog: false,
        privacyClauseDialog: false,
      };
    },
    watch: {
      $route: {
        handler: function (route) {
          this.redirect = route.query && route.query.redirect;
        },
        immediate: true,
      },
    },
    created() {
      // this.getCookie()
      if(process.env.VUE_APP_DISABLE_LOGIN=='true'){
        if(this.$route.path!='/admin_login'){
          window.location.href=process.env.VUE_APP_SSO_URL
          return;
        }
      }

      sessionStorage.clear("agencyId");
    },
    methods: {
      getDefaultRouer() {
        return localStorage.getItem("defaultRouter");
      },
      handleLoginSuccess(){
        this.$router.push({
          path: decodeURIComponent(this.redirect||'') || this.getDefaultRouer() || "/index",
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url("https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/loginBcg.png");
    background-size: cover;
  }
  .title {
    margin: 0px auto 20px auto;
    text-align: center;
    color: #707070;
  }

  .login-form {
    border-radius: 6px;
    background: #ffffff;
    width: 340px;
    height: 502px;
    position: relative;

    ::v-deep .el-form-item--small.el-form-item {
      margin-bottom: 22px;
    }

    .logo-info {
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
      .logo-image {
        width: 144px;
        height: 40px;
        display: inline-block;
        vertical-align: middle;
      }
    }
    .login-container {
      width: 100%;
      .login-tab {
        width: 100%;
        margin: 0;
        padding: 0;
        line-height: 30px;
        border-bottom: 1px solid #e5e5e5;
        height: 32px;
        cursor: pointer;
        li {
          display: inline-block;
          width: 33.3%;
          text-align: center;
          list-style: none;
          font-size: 16px;
          font-weight: 400;
          color: #bababa;

          &.active {
            color: #0078f8;
            // position: relative;
            border-bottom: 2px solid #0078f8;
            // &:after {
            //   content: "";
            //   display: block;
            //   // position: absolute;
            //   width: 100%;
            //   // left: 50%;
            //   bottom: 0px;
            //   height: 4px;
            //   background: #0078F8;
            //   -webkit-transform: scaleY(0.5);
            //   transform: scaleY(0.5);
            //   z-index: 1;
            //   // margin-left: -57px;
            // }
          }
        }
      }
      .code-login {
        width: 100%;
        padding: 30px;
        box-sizing: border-box;
      }
      .qr-login {
        width: 100%;
        margin: 0 auto;
        padding: 60px 0 40px;
        img {
          display: block;
          width: 166px;
          height: 166px;
          text-align: center;
          margin: auto;
          cursor: pointer;
        }
        p {
          margin: 20px auto 0;
          padding: 0;
          width: 100%;
          text-align: center;
        }
      }
    }
    .el-input {
      height: 32px;
      input {
        height: 32px;
        line-height: 32px;
      }
    }
    .input-icon {
      height: 39px;
      width: 14px;
      margin-left: 2px;
    }
  }
  .login-tip {
    font-size: 13px;
    text-align: center;
    color: #bfbfbf;
  }
  .login-code {
    width: 33%;
    height: 32px;
    float: right;
    img {
      cursor: pointer;
      vertical-align: middle;
    }
  }
  .phone-login {
    padding: 30px;
  }
  .el-login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
  }
  .login-code-img {
    height: 32px;
  }
  /*注册*/
  .register-form {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    .register-select {
      margin: 0 auto 20px;
    }
  }
</style>
